import Layout from '../components/layout/Layout';
import LabelText from '../components/LabelText';
// import CustomerCard from '../components/CustomerCard';
// import customerData from '../data/customer-data';
import { Link } from 'gatsby';
import Header2 from '../components/layout/Header2';
import { useIntl } from 'gatsby-plugin-intl';
import { window } from 'browser-monads';
import SectionAproposImg from '../svg/SectionAproposImg';

const Apropos = () => {
  const language = window.location.pathname.substring(0, 3);
  const intl = useIntl();
  const apropos = intl.formatMessage({ id: 'apropos' });
  const aproposText = intl.formatMessage({ id: 'aproposText' });
  // const equipe = intl.formatMessage({ id: 'equipe' });
  const goBack = intl.formatMessage({ id: 'goback' });
  return (
    <Layout>
      <Header2 />
      <div className='mentions'>
        <LabelText className='mb-8 text-gray-600 text-3xl text-center'>{apropos}</LabelText>
        <SectionAproposImg />
        <p className='text-lg bloc'>{aproposText}</p>
      </div>
      {/* <p className='text-gray-600 text-3xl text-center my-4'>{equipe}</p>
      <div className='container-cards'>
        <div className='flex flex-col md:flex-row md:-mx-3'>
          {customerData.map((customer) => (
            <div key={customer.customerName} className='flex-1 px-3'>
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div> */}
      <div className='goBack'>
        <Link className='mb-8 text-gray-600 text-3xl text-center' to={language + '/'}>{goBack}</Link>
      </div>
    </Layout>
  );
};

export default Apropos;
